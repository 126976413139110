import {
  FolderViewOutlined,
  ProjectOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  UserOutlined,
  MacCommandOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/mainLayout.css";
import {
  isDeliveryManager,
  userRole,
  timesheetAdmin,
  hrRole,
} from "../utils/userDetails";
const { SubMenu } = Menu;

function AdminSider() {
  const navigate = useNavigate();
  const location = useLocation();

  const hasViewTimesheetAccess = timesheetAdmin || isDeliveryManager || hrRole;

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  const items = [
    {
      key: "1",
      icon: <CalendarOutlined />,
      label: "Calendar",
      path: "/",
    },
    {
      key: "2",
      icon: <ProjectOutlined />,
      label: "Timesheet",
      path: "/timesheet",
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "My View",
      path: "/my-view",
    },
  ];

  if (hrRole) {
    items.push({
      key: "4",
      icon: <DashboardOutlined />,
      label: "Dashboard",
      path: "/approval-dashboard",
    });
  }

  if (isDeliveryManager) {
    items.push({
      key: "5",
      icon: <CheckSquareOutlined />,
      label: "Approve Timesheet",
      path: "/timesheet-approval",
    });
  }

  items.push({
    key: "6",
    icon: <MacCommandOutlined />,
    label: "Manage Timesheet",
    children: [
      {
        key: "7",
        label: "Add Project",
        path: "/addProject",
      },
      {
        key: "8",
        label: "Link Employee",
        path: "/add-timesheet",
      },
    ],
  });

  if (hasViewTimesheetAccess) {
    items.push({
      key: "9",
      icon: <FolderViewOutlined />,
      label: "View Timesheet",
      children: [
        {
          key: "10",
          label: "Project View",
          path: "/project-view",
        },
        {
          key: "11",
          label: "Employee View",
          path: "/employee-view",
        },
      ],
    });
  }

  // Adjust logic to select the correct menu item based on the current path
  const findSelectedKey = (path) => {
    let selectedKey;
    items.forEach((item) => {
      if (item.path === path) {
        selectedKey = item.key;
      }
      if (item.children) {
        item.children.forEach((childItem) => {
          if (childItem.path === path) {
            selectedKey = childItem.key;
          }
        });
      }
    });
    return selectedKey;
  };

  const selectedKey = findSelectedKey(location.pathname);
  const openKeys = items
    .filter(
      (item) =>
        item.children &&
        item.children.some((child) => location.pathname.startsWith(child.path))
    )
    .map((item) => item.key);

  return (
    <div
      style={{
        position: "sticky",
        top: "0",
        zIndex: "100",
        background: "#fff",
      }}
    >
      <Menu
        theme="light"
        selectedKeys={[selectedKey]} // Set selected keys based on the current route
        defaultOpenKeys={openKeys}
        mode="inline"
      >
        {items.map((item) =>
          item.children && item.children.length > 0 ? (
            <SubMenu
              key={item.key}
              icon={item.icon}
              title={item.label}
              className="sub-group"
            >
              {item.children.map((childItem) => (
                <Menu.Item
                  key={childItem.key}
                  onClick={() => handleMenuItemClick(childItem.path)}
                  style={{
                    textAlign: "left",
                    marginLeft: "12px",
                  }}
                >
                  {childItem.label}
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => handleMenuItemClick(item.path)}
              className="sidebar-menu-item"
              style={{ fontSize: "14px", textAlign: "left" }}
            >
              {item.label}
            </Menu.Item>
          )
        )}
      </Menu>
    </div>
  );
}

export default AdminSider;
