import { Layout } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import AdminSider from "../Sider/AdminSider";
import "../Styles/mainLayout.css";
const { Sider, Content } = Layout;

const siderStyle = {
  backgroundColor: "transparent",
};

function AdminLayout() {
  return (
    <div>
      <Layout>
        <Layout>
          <Sider width="15%" style={siderStyle} className="side-bar">
            <AdminSider />
          </Sider>

          <Content width="80%">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default AdminLayout;
