import { Layout } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";
import "../Styles/mainLayout.css";
import CustomSider from "../Sider/CustomSider";
const { Sider, Content } = Layout;

const siderStyle = {
  backgroundColor: "transparent",
};

function EmployeeLayout() {
  return (
    <div>
      <Layout className="main-layout">
        <Layout>
          <Sider width="15%" style={siderStyle} className="sider">
            <CustomSider />
          </Sider>

          <Content width="80%">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default EmployeeLayout;
