import {
  Button,
  Form,
  Input,
  Select,
  Typography,
  notification,
  Col,
  DatePicker,
  Flex,
  Row,
  InputNumber,
  Table,
  Space,
} from "antd";
import "../../App.css";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { Project, linkEmployees } from "../../api";
import {
  CheckCircleFilled,
  CheckOutlined,
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { RxCross1 } from "react-icons/rx";

const InternalProjectForm = ({ hide, added, project, isEdit }) => {
  const initialLeaveState = {
    startDate: dayjs(),
    endDate: dayjs(),
  };

  const [leave, setLeave] = useState(initialLeaveState);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(null);
  const [deliveryManager, setDeliveryManager] = useState([]);
  const [productManager, setProductManager] = useState([]);
  const { Text } = Typography;
  const reactQuillRef = useRef();
  const [errorMessageStart, setErrorMessageStart] = useState("");
  const [errorMessageEnd, setErrorMessageEnd] = useState("");
  const [selectEdit, setSelectEdit] = useState();
  const [disabledFields, setDisabledFields] = useState([]);
  const [currentCount, setCurrentCount] = useState(0);
  const [employeeDept] = useState({
    1: "PowerApps",
    2: "Cloud",
    3: "Frontend",
  });

  const departmentOptions = Object.entries(employeeDept).map(
    ([key, value]) => ({
      value: key,
      label: value,
    })
  );

  useEffect(() => {
    form.resetFields();

    form.setFieldsValue({
      projectDescription: null,
    });
    setDescription(null);
    console.log("project--=", project);
    if (isEdit && project) {
      form.setFieldsValue({
        projectName: project.projectName,
        projectDescription: project.projectDescription,
        deliveryManagerId: project.deliveryManagerId,
        productManagerId: project.productManagerId,
        startDate: dayjs(project.startDate),
        endDate: project.endDate ? dayjs(project.endDate) : null,
      });

      setDescription(project.projectDescription);
      setLeave({
        startDate: dayjs(project.startDate),
        endDate: dayjs(project?.endDate),
      });

      const disabledFieldsRow = Object.keys(project)
        .filter((key, index) => {
          if (key === "projectName" || project[key] === null) {
            return true;
          }
          return false;
        })
        .map((_, index) => index);

      setDisabledFields(disabledFieldsRow);
    } else {
      setDisabledFields([]);
    }
  }, [isEdit, project, form]);

  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };

  const getAllEmployees = async () => {
    try {
      const response = await linkEmployees.getAllEmployees();
      const newResponse = response.employees.map((employee) => {
        return {
          value: employee.employeeNo,
          label: `${employee.employeeName}  (${employee.employeeNo})`,
        };
      });
      setDeliveryManager(newResponse);
      setProductManager(newResponse);
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  const setStartDate = (value) => {
    if (value) {
      const formattedDate = dayjs(value);
      const endDate = leave.endDate;

      setLeave((prevLeave) => ({
        ...prevLeave,
        startDate: formattedDate,
      }));

      form.setFieldsValue({ startDate: formattedDate });

      if (formattedDate.isAfter(endDate)) {
        setErrorMessageStart("Start date cannot be greater than End date");
        setErrorMessageEnd("");
      } else {
        setErrorMessageStart("");
        setErrorMessageEnd("");
      }
    }
  };

  const setEndDate = (value) => {
    if (value) {
      const formattedEndDate = dayjs(value);
      const startDate = leave.startDate;

      setLeave((prevLeave) => ({
        ...prevLeave,
        endDate: formattedEndDate,
      }));

      form.setFieldsValue({ endDate: formattedEndDate });

      if (formattedEndDate.isBefore(startDate)) {
        setErrorMessageEnd("End date cannot be less than Start date");
        setErrorMessageStart("");
      } else {
        setErrorMessageEnd("");
        setErrorMessageStart("");
      }
    }
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      values.startDate = leave.startDate.format("YYYY-MM-DD");
      values.endDate = values?.endDate
        ? leave?.endDate?.format("YYYY-MM-DD")
        : null;

      if (isEdit) {
        await Project.addProject(values);
        openNotification(
          "Project Updated Successfully !!",
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        );
      } else {
        await Project.addProject(values);
        openNotification(
          "Project Added Successfully !!",
          <CheckCircleFilled style={{ color: "#52c41a" }} />
        );
      }
      form.resetFields();
      hide();
      added(true);
    } catch (error) {
      form.resetFields();
      hide();
      openNotification(
        "Failed to Add/Update Project",
        <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      );
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
      setDisabledFields([]);
    }
  };

  const handleDescription = (value) => {
    setDescription(value);
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const textContent = doc.body.textContent;
    form.setFieldsValue({
      projectDescription: textContent ? value : "",
    });
  };

  const checkCharacterCount = (event) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() > 2000 && event.key !== "Backspace")
      event.preventDefault();
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [originalEmployeeData, setOriginalEmployeeData] = useState(null);

  const handleEdit = (index) => {
    setSelectEdit(true);
    const employeesFields = form.getFieldValue("departments");
    setOriginalEmployeeData({ ...employeesFields[index] });
  };

  const handleReset = (index) => {
    const employeesFields = form.getFieldValue("departments");
    if (originalEmployeeData) {
      employeesFields[index] = { ...originalEmployeeData };
    }
    form.setFieldsValue({ employees: employeesFields });
    setSelectEdit(false);
  };

  const handleDepartmentSave = (field, index) => {
    // const employeesFields = form.getFieldValue("employees");

    // // const employeeStartDate = employeesFields[index]?.startDate;
    //  employeesFields[index] = {
    //      ...employeesFields[index],

    //     //  endDate: employeesFields[index]?.endDate || null,
    //     //  isBillable: employeesFields[index]?.isBillable || null,
    //     //  Active: employeesFields[index]?.Active || null,
    //  };

    //  form.setFieldsValue({ employees: employeesFields });

    form.setFieldsValue({
      projectName: project.projectName,
      projectDescription: project.projectDescription,
      deliveryManagerId: project.deliveryManagerId,
      productManagerId: project.productManagerId,
      startDate: dayjs(project.startDate),
      endDate: project.endDate ? dayjs(project.endDate) : null,
    });

    setSelectEdit(false);
  };

  return (
    <Form
      form={form}
      layout={"vertical"}
      initialValues={{
        layout: "vertical",
        remember: true,
      }}
      onFinish={onFinish}
      style={{
        padding: "1.5rem 1rem 0rem",
        margin: "auto",
        overflow: "hidden",
      }}
      className="userForm"
    >
      <div className="form">
        <Form.Item
          name="projectName"
          label={<Text style={{ fontSize: "16px" }}>Name</Text>}
          rules={[{ required: true, message: "Project Name is Required" }]}
          style={{ margin: "0 auto 24px" }}
        >
          <Input
            placeholder="Project Name"
            size="large"
            disabled={!!project}
            maxLength={500}
          />
        </Form.Item>

        <div
          style={{
            gap: "12px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Form.Item
            name="deliveryManagerId"
            label={<Text style={{ fontSize: "16px" }}>Delivery Manager</Text>}
            rules={[
              { required: true, message: "Delivery Manager is Required" },
            ]}
            style={{ width: "100%" }}
          >
            <Select
              showSearch
              placeholder="Select Delivery Manager"
              size="large"
              options={deliveryManager}
              filterOption={filterOption}
              optionFilterProp="children"
            />
          </Form.Item>

          <Form.Item
            name="productManagerId"
            label={<Text style={{ fontSize: "16px" }}>Product Manager</Text>}
            rules={[{ required: true, message: "Product Manager is Required" }]}
            style={{ width: "100%" }}
          >
            <Select
              showSearch
              placeholder="Select Product Manager"
              size="large"
              options={productManager}
              filterOption={filterOption}
              optionFilterProp="children"
            />
          </Form.Item>
        </div>

        <div
          style={{
            gap: "12px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Form.Item
            name="startDate"
            help={errorMessageStart}
            label={<Text style={{ fontSize: "16px" }}>Start Date</Text>}
            rules={[{ required: true, message: "Start Date is Required" }]}
            style={{ width: "100%" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Start Date"
              value={leave.startDate}
              onChange={(value) => setStartDate(value)}
              allowClear={false}
            />
          </Form.Item>

          <Form.Item
            name="endDate"
            help={errorMessageEnd}
            label={<Text style={{ fontSize: "16px" }}>End Date</Text>}
            style={{ width: "100%" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="End Date"
              value={leave.endDate}
              onChange={(value) => setEndDate(value)}
              allowClear={false}
            />
          </Form.Item>
        </div>

        <Form.List name="departments" initialValue={[{}]}>
          {(fields, { add, remove }) => {
            const columns = [
              {
                title: "Department",
                dataIndex: "departmentNo",
                padding: "8px 16px",
                width: "438px",
                render: (_, __, index) => (
                  <Form.Item
                    name={[index, "department"]}
                    rules={[{ required: true, message: "Select Department" }]}
                    style={{ marginBottom: "0px" }}
                  >
                    <Select
                      placeholder="Select Department"
                      options={departmentOptions}
                      disabled={!selectEdit && disabledFields.includes(index)}
                    />
                  </Form.Item>
                ),
              },
              {
                title: <span style={{ fontWeight: "400" }}>Hours</span>,
                dataIndex: "hours",
                width: "160px", 
                padding: "8px 16px",

                render: (_, __, index) => (
                  <Form.Item
                    name={[index, "hours"]}
                    rules={[{ required: true, message: "Please Enter hours" }]}
                    style={{ marginBottom: "0px" }}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      step={1}
                      disabled={!selectEdit && disabledFields.includes(index)}
                    />
                  </Form.Item>
                ),
              },
              {
                title: <span style={{ fontWeight: "400" }}>Actions</span>,
                key: "actions",
                width: "120px",
                padding: "8px 16px",
                render: (_, __, index) => (
                  <Form.Item style={{ marginBottom: "0px" }}>
                    {isEdit ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        {selectEdit ? (
                          <div style={{ gap: "10px", display: "flex" }}>
                            <span
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                color: "#52C41A",
                              }}
                              onClick={handleDepartmentSave}
                            >
                              {<CheckOutlined />}
                            </span>
                            <span
                              style={{ cursor: "pointer", display: "flex" }}
                              onClick={() => handleReset(index)}
                            >
                              {<RxCross1 color="#FF4D4F" />}
                            </span>
                          </div>
                        ) : (
                          <Button
                            icon={<EditOutlined />}
                            type="text"
                            style={{
                              color: "#2e77ff",
                              fontSize: "16px",
                            }}
                            onClick={() => handleEdit(index)}
                          ></Button>
                        )}

                        <Button
                          icon={<DeleteOutlined />}
                          type="text"
                          style={
                            fields.length === 1
                              ? {
                                  cursor: "not-allowed",
                                  color: "rgba(0, 0, 0, 0.25)",
                                  pointerEvents: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                }
                              : {
                                  fontSize: "16px",
                                }
                          }
                          onClick={() => remove(index)}
                        ></Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          onClick={() => remove(index)}
                          style={
                            fields.length === 1
                              ? {
                                  cursor: "not-allowed",
                                  color: "rgba(0, 0, 0, 0.25)",
                                  pointerEvents: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                }
                              : { cursor: "pointer" }
                          }
                        >
                          <DeleteOutlined />
                        </span>
                      </div>
                    )}
                  </Form.Item>
                ),
              },
            ];

            const dataSource = fields.map((field, index) => ({
              key: field.key,
            }));

            const handleAddRow = () => {
              add({}, 0);
            };
            return (
              <>
                <Row
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <span>
                    {
                      <span
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginRight: "6px",
                        }}
                      >
                        *
                      </span>
                    }
                    Choose Departments
                  </span>
                  <div
                    style={{ display: "flex", gap: "10px", cursor: "pointer" }}
                    onClick={handleAddRow}
                  >
                    {" "}
                    <span>{<PlusOutlined />}</span> <span>Add Department</span>
                  </div>
                </Row>
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  style={{
                    border: "1px solid #D9D9D9",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                />
              </>
            );
          }}
        </Form.List>

        <Form.Item
          name="projectDescription"
          label={<Text style={{ fontSize: "16px" }}>Description</Text>}
          rules={[
            {
              required: true,
              message: "Task Performed is Required",
            },
          ]}
          style={{ marginTop: "20px" }}
        >
          <div
            style={{
              borderRadius: "2px",
              overflow: "hidden",
              zIndex: "10",
              height: "200px",
              marginBottom: "1rem",
            }}
          >
            <ReactQuill
              style={{
                width: "100%",
                height: "150px",
              }}
              ref={reactQuillRef}
              onKeyDown={checkCharacterCount}
              value={description}
              onChange={handleDescription}
              theme="snow"
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
            />
          </div>
        </Form.Item>
      </div>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={loading}
          style={{ display: "block", width: "100%" }}
        >
          Save
        </Button>
      </Form.Item>
      {contextHolder}
    </Form>
  );
};

export default InternalProjectForm;
